export const GET_UNIVERSITIES_REQUESTED = 'GET_UNIVERSITIES_REQUESTED';
export const GET_UNIVERSITIES_SUCCESS = 'GET_UNIVERSITIES_SUCCESS';
export const GET_UNIVERSITIES_FAILED = 'GET_UNIVERSITIES_FAILED';
export const GET_UNIVERSITIES = 'GET_UNIVERSITIES';
export const SORT_LIST = "SORT_LIST";
export const SEARCH_LIST = "SEARCH_LIST";
export const ON_CLEAR = "ON_CLEAR";
export const REMOVE_DATA = "REMOVE_DATA";
export const GET_PRODUCTS_REQUESTED= "GET_PRODUCTS_REQUESTED";
export const ADD_TO_CART_REQUESTED = 'ADD_TO_CART_REQUESTED';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const FETCH_SUGGESTIONS_SUCCESS= 'FETCH_SUGGESTIONS_SUCCESS';
export const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const FETCH_SUGGESTIONS_REQUESTED = 'FETCH_SUGGESTIONS_REQUESTED';
export const UPDATE_CART_LOCAL = 'UPDATE_CART_LOCAL';
export const UPDATE_CART_COUNT ="UPDATE_CART_COUNT";
export const REMOVE_CART ="REMOVE_CART";
export const SEARCH_FILTER_REQUESTING ="SEARCH_FILTER_REQUESTING";
export const APPLY_FILTER = "APPLY_FILTER";
export const PLACE_ORDER_REQUESTING ="PLACE_ORDER_REQUESTING";
export const PLACE_ORDER_SUCCESS="PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE="PLACE_ORDER_FAILURE";
export const SEND_OTP_SUCCESS="SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL="SEND_OTP_FAIL";




